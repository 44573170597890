<template>

  <div class="main">
    <img src="../../assets/img_xc.png" style="width: 100%;display: block" alt="">
<!--    <img src="../../assets/wx-share-4.jpg" style="width: 100%;display: block" alt="">-->

    <div class="button"  @click="toMyCs">
      更多内容 点击进入星城玩家
    </div>
  </div>

</template>

<script>
import {getUserAgentUa, showFailToast, userAgentUa} from "../../utils/util";

export default {
  name: "wxshare",

  setup(){

    const wakeApp = (param) => {

      const config = {
        packageName: 'com.changsha.apps.android.mycs',
        schema: 'cssmy',
        web_link: 'https://mycs.csbtv.com/cssmyapp/'
      }
      let urlParam = {
        android_min_version: '54',
        appId: 'cssmy',
        channel: '3',
        city: '430100',
        code: '',
        contentUrl: '',
        imageUrl: 'https://cdn-oss.zhcs.csbtv.com/zhcs-prd/images/613c5b5d856fb900013ad050',
        isWebService: '3', // isWebService 1: 服务  2：H5  3：视频
        name: '',
        shareDesc: '',
        shareUrl: ''
      }
      urlParam = {...urlParam, ...param}
      console.log(urlParam)
      // 在微博或QQ中打开分享链接提示用户用浏览器打开
      if(userAgentUa.isInApp()){
        eshimin.bytedanceApplogEvent({
          eventName: 'home_click_button', // 例子
          eventParams: {} ,//例子
          onResponse:  (res)=> {
            console.log("bytedanceApplogEvent responseData:" + res);
          }
        })
        window.location.href = window.location.origin;

      }else  if (userAgentUa.android() && userAgentUa.weibo()) {
        console.log('请用浏览器打开')
        showFailToast(userAgentUa.android )
      } else if (userAgentUa.iphone() && (userAgentUa.weibo() || userAgentUa.mqq())) {
        console.log('请用浏览器打开')
        showFailToast('请用浏览器打开2')
      } else {
        let url,
          str,
          urlLink = "";
        for (let i in urlParam) {
          if (i == "imageUrl" || i == "shareUrl") {
            // 将参数'imageUrl'和 'shareUrl'编码
            urlParam[i] = encodeURIComponent(urlParam[i]);
          }
          // 拼接参数
          if (i !== "contentUrl") {
            urlLink += `${i}=${urlParam[i]}&`;
          }
        }
        urlLink = urlLink.substr(0, urlLink.length - 1);
        if (userAgentUa.android()) {
          str = `${config.schema}://welcome.applink/open?${urlLink}`;
          str =
            `https://a.app.qq.com/o/simple.jsp?pkgname=${config.packageName}&android_schema=` +
            encodeURIComponent(str);
          url = str;
        }
        if (userAgentUa.iphone()) {
          url = `${config.web_link}?${urlLink}`; //打开某手机上的某个app应用
        }
        console.log('🐞-[ url ]', url)
        window.location.href = url || config.web_link; //打开某手机上的某个app应用
      }
    }

    const toMyCs = () => {
      wakeApp({
        isWebService: 2,
        contentUrl: window.location.origin,
        shareUrl: window.location.origin,
        name: '星城玩家',
        shareDesc: '快和我一起打卡书香长沙数字地图，领免费数字徽章和最高100元现金图书券',
      })
    }

    return {
      toMyCs,

    }
  }
}
</script>

<style scoped lang="scss">

.main {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.button {
  color: white;
  font-weight: bold;
  background-color: #FB4D09;
  border-radius: 4px;
  position:  fixed;
  bottom: 10px;
   width: calc(100% - 40px);
  height: 40px;
  line-height: 40px;
  text-align: center;
   margin-left: 20px;
}

</style>
